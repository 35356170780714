import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import FlyersImg from "../../components/images/printing/flyers"

export default function Flyers() {
    return (
        <>
            <Layout>
                <SEO title="Flyers" />
                <Display
                    title="Flyers"
                    description="Get the word out! Flyers and direct mail campaigns are an excellent marketing tool to increase awareness and share news, offers, stories, products and more. Talk to us about making an impact with cardstock flyers."
                    prev="/printing/brochures"
                    next="/printing/posters-signage"
                    image={<FlyersImg />}
                />
            </Layout>                
        </>
    )
}
